import { LOAD_STATUS_STALE } from 'state-domains/constants';
import {
    completeReducer,
    failureReducer,
    mappedReducer,
    pendingReducer,
    staleReducer,
    typeComplete,
    typeFail,
    typePending,
} from 'state-domains/utils/redux';
import {
    CLEAR_EXPORT_TEMPLATES,
    CLEAR_EXPORT_TEMPLATES_ACTION_STATE,
    CLONE_EXPORT_TEMPLATES,
    DELETE_EXPORT_TEMPLATES,
    EXPORT_TEMPLATES,
    SAVE_EXPORT_TEMPLATES,
} from 'state-domains/types/actionTypes';
import { BaseAction } from 'state-domains/types';

import { DataError, DataState } from '../types';

const INITIAL_DATA_STATE = {
    items: [] as any[],
    status: LOAD_STATUS_STALE,
    error: null,
};

export const INITIAL_STATE: DataState = {
    exportTemplatesState: {
        ...INITIAL_DATA_STATE,
        actionState: {
            status: LOAD_STATUS_STALE,
            error: null,
        },
    },
};

const loadExportTemplatesPendingReducer = (state: DataState): DataState => ({
    ...state,
    exportTemplatesState: pendingReducer(state.exportTemplatesState),
});

const loadExportTemplatesSuccessReducer = (state: DataState, actions: BaseAction) => ({
    ...state,
    exportTemplatesState: completeReducer({
        ...state.exportTemplatesState,
        items: actions.payload,
    }),
});

const loadExportTemplatesFailReducer = (
    state: DataState,
    action: BaseAction<DataError>,
): DataState => ({
    ...state,
    exportTemplatesState: failureReducer({
        ...state.exportTemplatesState,
        error: action.payload.error,
    }),
});

const clearExportTemplates = (state: DataState) => ({
    ...state,
    exportTemplatesState: staleReducer({
        items: [],
        actionState: staleReducer({}),
    }),
});

const clearExportTemplatesActionState = (state: DataState) => ({
    ...state,
    exportTemplatesState: {
        ...state.exportTemplatesState,
        actionState: staleReducer({}),
    },
});

const exportTemplatesActionStatePendingReducer = (state: DataState): DataState => ({
    ...state,
    exportTemplatesState: {
        ...state.exportTemplatesState,
        actionState: pendingReducer({}),
    },
});

const cloneExportTemplatesSuccessReducer = (state: DataState, action: BaseAction) => {
    const payload = { ...(action?.payload && { id: action?.payload }) };
    return {
        ...state,
        exportTemplatesState: {
            ...state.exportTemplatesState,
            actionState: completeReducer(payload),
        },
    };
};

const exportTemplatesActionStateFailReducer = (
    state: DataState,
    action: BaseAction<DataError>,
): DataState => ({
    ...state,
    exportTemplatesState: {
        ...state.exportTemplatesState,
        actionState: failureReducer({
            error: action.payload.error,
        }),
    },
});

const deleteExportTemplatesSuccessReducer = (state: DataState, action: BaseAction) => {
    const { payload } = action;
    const {
        exportTemplatesState: { items },
    } = state;
    const newItems = items.filter((x) => x.id !== payload.id);
    return {
        ...state,
        exportTemplatesState: {
            ...state.exportTemplatesState,
            items: newItems,
            actionState: completeReducer({}),
        },
    };
};

const saveExportTemplatesSuccessReducer = (state: DataState, action: BaseAction) => {
    const { payload } = action;
    const {
        exportTemplatesState: { items },
    } = state;
    const newItems = items.filter((x) => x.id !== payload.id);
    return {
        ...state,
        exportTemplatesState: {
            ...state.exportTemplatesState,
            items: [...newItems, payload],
            actionState: completeReducer({}),
        },
    };
};

export const reducer = mappedReducer(INITIAL_STATE, {
    [typePending(EXPORT_TEMPLATES)]: loadExportTemplatesPendingReducer,
    [typeComplete(EXPORT_TEMPLATES)]: loadExportTemplatesSuccessReducer,
    [typeFail(EXPORT_TEMPLATES)]: loadExportTemplatesFailReducer,
    [CLEAR_EXPORT_TEMPLATES]: clearExportTemplates,
    [CLEAR_EXPORT_TEMPLATES_ACTION_STATE]: clearExportTemplatesActionState,
    [typePending(CLONE_EXPORT_TEMPLATES)]: exportTemplatesActionStatePendingReducer,
    [typeComplete(CLONE_EXPORT_TEMPLATES)]: cloneExportTemplatesSuccessReducer,
    [typeFail(CLONE_EXPORT_TEMPLATES)]: exportTemplatesActionStateFailReducer,
    [typePending(DELETE_EXPORT_TEMPLATES)]: exportTemplatesActionStatePendingReducer,
    [typeComplete(DELETE_EXPORT_TEMPLATES)]: deleteExportTemplatesSuccessReducer,
    [typeFail(DELETE_EXPORT_TEMPLATES)]: exportTemplatesActionStateFailReducer,
    [typePending(SAVE_EXPORT_TEMPLATES)]: exportTemplatesActionStatePendingReducer,
    [typeComplete(SAVE_EXPORT_TEMPLATES)]: saveExportTemplatesSuccessReducer,
    [typeFail(SAVE_EXPORT_TEMPLATES)]: exportTemplatesActionStateFailReducer,
});
